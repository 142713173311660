document.addEventListener('DOMContentLoaded', function() {
    initMenu();
    updateMenu();
});

function initMenu() {
    $(window).on('hashchange', updateMenu);
}

function setActiveMobileItem() {
    let isSet = false;
    const mobileDM = document.querySelector('.dm__menu_mobile');
    const toggleContainer = mobileDM.querySelector('.dropdown-toggle');
    const currentActiveMenuItem = toggleContainer.querySelector('.dropdown-item');
    const dropdownMenu = mobileDM.querySelector('.dropdown-menu');
    const menuItemList = dropdownMenu.querySelectorAll('.dropdown-item');

    menuItemList.forEach((menuItem) => {
        if (menuItem.hash === location.hash) {
            if (currentActiveMenuItem) {
                dropdownMenu.appendChild(currentActiveMenuItem);
            }

            isSet = true;
            toggleContainer.insertBefore(menuItem, toggleContainer.childNodes[0]);
        }
    })

    if (!isSet) {
        toggleContainer.insertBefore(menuItemList[0], toggleContainer.childNodes[0]);
    }
}

function updateMenu() {
    const locationHash = location.hash.substring(1, location.hash.length);
    console.log(locationHash);
    let wasFound = false;
    const $activeItem = $('.dm__item.active');
    $activeItem.removeClass('active');
    $('.dm__item').each((i, item) => {
        const $item = $(item);
        if ($item.data('id').toString() === locationHash) {
            wasFound = true;
            $item.addClass('active');
            console.log(window.innerHeight);
            if (window.innerWidth < 1000) {
                $('html, body').animate({
                    scrollTop: $item.parents('.dm').offset().top,
                });
            }
        }
    });
    if (!wasFound) {
        $('.dm__item').first().addClass('active');
    }
    setActiveMobileItem();
}
